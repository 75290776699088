import React, { useContext, useReducer } from 'react';
import { ThemeContext } from './theme.context';

const INITIAL_STATE = {
  darkMode: false,
};

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'TOGGLEMODE':
      return {
        ...state,
        darkMode: action.payload,
      };
    default:
      return state;
  }
};

export const ThemeProvider = ({ children }) => {
  const [themeState, themeDispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <ThemeContext.Provider
      value={{
        themeState,
        themeDispatch,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
