// custom typefaces
import 'typeface-montserrat';
import 'typeface-merriweather';
import React from 'react';
import { ThemeProvider } from './src/context/theme/theme.provider';
require('prismjs/themes/prism.css');
require('katex/dist/katex.min.css');
export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
);
